@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

html {
  height: 100%;
}
body {
  height: 100dvh;
  margin: 0;
}
#root {
  height: 100%;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

div[data-test-id='virtuoso-item-list'] {
  min-height: 100%;
}

#fps-slowdown-make-sound-noisy {
  width: 1px;
  height: 1px;
  position: absolute;
  animation-name: anim-void;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  z-index: 500;
}
@keyframes anim-void {
  from {
    top: 0px;
  }
  to {
    top: 1px;
  }
}
